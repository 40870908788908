/**
 * このファイル内では、exportしない関数や変数は
 * 可読性を重視して通常の命名規則に従っていないものがある。
 */

const yyyy = (date: Date): string => `${date.getFullYear()}`
const MM = (date: Date): string => `${date.getMonth() + 1}`.padStart(2, '0')
const M = (date: Date): string => `${date.getMonth() + 1}`
const dd = (date: Date): string => `${date.getDate()}`.padStart(2, '0')
const d = (date: Date): string => `${date.getDate()}`
const HH = (date: Date): string => `${date.getHours()}`.padStart(2, '0')
const H = (date: Date): string => `${date.getHours()}`
const mm = (date: Date): string => `${date.getMinutes()}`.padStart(2, '0')
const m = (date: Date): string => `${date.getMinutes()}`
const ss = (date: Date): string => `${date.getSeconds()}`.padStart(2, '0')
const s = (date: Date): string => `${date.getSeconds()}`

const GETDAY_TO_EEEE = {
	0: '日曜日',
	1: '月曜日',
	2: '火曜日',
	3: '水曜日',
	4: '木曜日',
	5: '金曜日',
	6: '土曜日',
} as const
const EEEE = (date: Date): string => GETDAY_TO_EEEE[date.getDay() as keyof typeof GETDAY_TO_EEEE]

const GETDAY_TO_E = {
	0: '日',
	1: '月',
	2: '火',
	3: '水',
	4: '木',
	5: '金',
	6: '土',
} as const
const E = (date: Date): string => GETDAY_TO_E[date.getDay() as keyof typeof GETDAY_TO_E]

// prettier-ignore
const FORMAT_TEMPLATE_TO_FUNC = {
  'yyyy/MM/dd HH:mm:ss': (_: Date) => `${yyyy(_)}/${MM(_)}/${dd(_)} ${HH(_)}:${mm(_)}:${ss(_)}`,
  'yyyy/MM/dd HH:mm':    (_: Date) => `${yyyy(_)}/${MM(_)}/${dd(_)} ${HH(_)}:${mm(_)}`,
  'yyyy/MM/dd':          (_: Date) => `${yyyy(_)}/${MM(_)}/${dd(_)}`,
  'yyyy/M/d':            (_: Date) => `${yyyy(_)}/${M(_)}/${d(_)}`,
  'yyyyMMdd':            (_: Date) => `${yyyy(_)}${MM(_)}${dd(_)}`,
  'yyyy':                (_: Date) => `${yyyy(_)}`,
  'MM/dd':               (_: Date) => `${MM(_)}/${dd(_)}`,
  'MM/dd HH:mm':         (_: Date) => `${MM(_)}/${dd(_)} ${HH(_)}:${mm(_)}`,
  'MM/dd(EEEE)':         (_: Date) => `${MM(_)}/${dd(_)}(${EEEE(_)})`,
  'MM/dd(E)':            (_: Date) => `${MM(_)}/${dd(_)}(${E(_)})`,
  'M/d':                 (_: Date) => `${M(_)}/${d(_)}`,
  'M/d(EEEE)':           (_: Date) => `${M(_)}/${d(_)}(${EEEE(_)})`,
  'M/d(E)':              (_: Date) => `${M(_)}/${d(_)}(${E(_)})`,
  'M/d(E) HH':           (_: Date) => `${M(_)}/${d(_)}(${E(_)}) ${HH(_)}`,
  'M':                   (_: Date) => `${M(_)}`,
  'M月':                 (_: Date) => `${M(_)}月`,
  'd(E)':                (_: Date) => `${d(_)}(${E(_)})`,
  'd日(E)':              (_: Date) => `${d(_)}日(${E(_)})`,
  'yyyy-MM-dd HH:mm:ss': (_: Date) => `${yyyy(_)}-${MM(_)}-${dd(_)} ${HH(_)}:${mm(_)}:${ss(_)}`,
  'yyyy-MM-dd HH:mm':    (_: Date) => `${yyyy(_)}-${MM(_)}-${dd(_)} ${HH(_)}:${mm(_)}`,
  'yyyy-MM-dd':          (_: Date) => `${yyyy(_)}-${MM(_)}-${dd(_)}`,
  'MM-dd':               (_: Date) => `${MM(_)}-${dd(_)}`,
  'EEEE':                (_: Date) => `${EEEE(_)}`,
  'E':                   (_: Date) => `${E(_)}`,
  'HH:mm:ss':            (_: Date) => `${HH(_)}:${mm(_)}:${ss(_)}`,
  'HH:mm':               (_: Date) => `${HH(_)}:${mm(_)}`,
  'H:mm':                (_: Date) => `${H(_)}:${mm(_)}`,
  'HH':                  (_: Date) => `${HH(_)}`,
  'H':                   (_: Date) => `${H(_)}`,
  'mm':                  (_: Date) => `${mm(_)}`,
  'm':                   (_: Date) => `${m(_)}`,
  'ss':                  (_: Date) => `${ss(_)}`,
  's':                   (_: Date) => `${s(_)}`,
} as const

export type DateFormatTemplate = keyof typeof FORMAT_TEMPLATE_TO_FUNC

interface GetDateFormatterOptions {
	/** 渡された値が `null` や `undefined` の場合に返す文字列 */
	fallback?: string
}

type FormatFunc<OPTIONS extends GetDateFormatterOptions> = OPTIONS extends {
	fallback: string
}
	? (date: Date | undefined | null) => string
	: (date: Date) => string

/**
 * `Date` オブジェクトをフォーマットする関数を取得する。
 * date-fns の `format` よりも高速にフォーマット処理させることを目的として
 * この関数を用意している。
 *
 * @param format フォーマットテンプレート
 * @param options オプション。詳細は {@link GetDateFormatterOptions} を参照。
 */
export function getDateFormatter<OPTIONS extends GetDateFormatterOptions>(
	format: DateFormatTemplate,
	options: OPTIONS = {} as OPTIONS
): FormatFunc<OPTIONS> {
	const { fallback } = options

	return fallback !== undefined
		? (date: Date | undefined | null) => (date ? FORMAT_TEMPLATE_TO_FUNC[format](date) : fallback)
		: (FORMAT_TEMPLATE_TO_FUNC[format] as FormatFunc<OPTIONS>)
}
